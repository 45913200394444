<template>
  <section id="privacy">
    <TopBanner
      :mainBg="`${require('@/assets/img/privacy/img.png')}`"
      :pageName="'隱私權頁面'"
      :titleWord="[
        {
          word: 'Privacy',
          ifBold: true,
        },
      ]"
      :bottomBgColor="'#fff'"/>

    <div class="w1400 main_padding">

      <h5 class="sub_title txt-bold">隱私權聲明</h5>
      <br>

      <div class="term">
        <p class="term_title txt-bold">
          <span>
            隱私權聲明之認同
          </span>
        </p>
        <p>
          如您使用本網站，即表示您接受本隱私權聲明。如果您不接受本隱私權聲明時，請勿在本網站上提供您的個人資料。
        </p>
      </div>

      <div class="term">
        <p class="term_title txt-bold">
          <span>
            免責事項
          </span>
        </p>
        <p>
          本網站內容是由日商麒麟技術股份有限公司台北分公司(以下簡稱日商麒麟技術)所提供。
          經由本網站所連結的其他網站內並非日商麒麟技術可以控制，
          基此，我們無法對於任何連結網站的內容負責。
          日商麒麟技術提供這些連結只為了讓您使用上方便，
          日商麒麟技術不擔保其真實性、完整性、即時性或可信度，該等個人、公司或組織亦不因此而當然與日商麒麟技術有任何僱佣、委任、代理、合夥或其他類似之關係。
          <br><br>
          日商麒麟技術員工及其相關協力廠商在網路上、或任何社交媒體上的聲明不一定代表日商麒麟技術的官方觀點。有關正式官方意見和公告，請以日商麒麟技術所屬官方網站發布之新聞稿為主。
        </p>
      </div>

      <div class="term">
        <p class="term_title txt-bold">
          <span>
            著作權
          </span>
        </p>
        <p>
          本網站所有內容，包括但不限於文字、肖像、圖表、音樂、動畫文件、錄影及所有經日商麒麟技術所策劃、製作置於網站上之內容均受著作權及其他智慧財產權所保護，
          且均為日商麒麟技術或其權利人所有，除事先經日商麒麟技術或其權利人之合法授權外，
          未經日商麒麟技術書面授權，您不得對本網站內容基於商業用途或發行之目的進行重製、散佈，亦不得修改或轉載至其他網站或以其他任何形式、基於任何目的加以使用，否則應自負所有法律之責任。
          <br><br>
          如因商業用途須取得授權時，請聯絡下列聯絡資訊：
          <br>
          日商麒麟技術股份有限公司台北分公司
          <br>
          電子信箱：
          <a href="mailto:sales@kirineng.tw">sales@kirineng.tw</a>
        </p>
      </div>

      <br><br>

      <h5 class="sub_title txt-bold">個人資料保護聲明</h5>
      <br>

      <div class="term">
        <p class="term_title txt-bold">
          <span>
            蒐集您的個人資訊
          </span>
        </p>
        <p>
          「個人資訊」指的是可以識別身份或讓我們連絡您的資訊，
          以提供或執行您所要求的服務或交易。
          我們所蒐集的個人資訊可能包括您的姓名、職稱、服務公司或組織名稱、工作電子郵件、公司或個人電話、工作內容的相關資訊、公司相關資訊。
        </p>
      </div>

      <div class="term">
        <p class="term_title txt-bold">
          <span>
            使用您的個人資訊
          </span>
        </p>
        <p>
          我們會使用您的個人資訊，在您的允許下，我們會提供有關日商麒麟技術的服務資訊給您。
          <br>
          我們會將網站到訪資料與匿名的統計資料合併作為研究之用，
          我們也會彙整這項資訊來提供更相關的內容。
          在日商麒麟技術網站上的某些限制存取區域中，
          如果您同意，我們會將網站到訪資料與您的個人資料結合來為您提供個人化的內容。
          如果您不同意，我們將不提供個人化的服務，也不會將您的個人資訊與網站到訪資料合併。
          <br><br>
          日商麒麟技術得依據法律規定或在下列情況下基於善意相信確有必要時，
          才會透露您的個人資訊：
          <br>
          (a) 遵守法律規定或日商麒麟技術或網站上的法律程序；
          <br>
          (b) 保護及防衛日商麒麟技術及其網站系列的權益或財產權；
          <br>
          (c) 在緊急狀況下為保護日商麒麟技術員工或代理商、日商麒麟技術產品或服務的使用者或社會大眾的個人安全而採取的行動。
          <br><br>
          在「網站」上蒐集的個人資訊可能會在麒麟控股，或在日商麒麟技術或其關係企業、分公司或代理商所在的任何國家進行儲存或處理。
        </p>
      </div>

      <div class="term">
        <p class="term_title txt-bold">
          <span>
            個人資訊的安全性
          </span>
        </p>
        <p>
          日商麒麟技術會致力保護您個人資訊的安全，
          我們會運用各種安全技術和程序來協助保護您的個人資料，避免未經授權的存取、
          使用或揭露。例如，我們會將您提供的個人資訊儲存在控管嚴密的設施內，
          只有少數人能存取的電腦伺服器中。
          此外，當我們在網際網路上傳送重要的個人資訊時，我們會利用加密來保護它，例如Secure Socket Layer（SSL）通訊協定。
        </p>
      </div>

      <div class="term">
        <p class="term_title txt-bold">
          <span>
            使用 Cookie
          </span>
        </p>
        <p>
          我們在本「網站」上使用 Cookie，
          以確保註冊程序的完整性並進行「網站」的個人化。
          Cookie是一個小型的文字檔案，由網頁伺服器將它放到您的硬碟中。
          Cookie 不能用來執行程式，也不會將病毒傳送到您的電腦中。
          Cookie 是專門針對您個人指定的，
          而且只有位於發 Cookie 給您的網域中的網路伺服器才能讀取。
          <br><br>
          Cookie 的其中一個主要功能是讓您節省時間。
          例如，當您將網頁個人化或是在網站中瀏覽時，
          Cookie 可以幫助網站記住您的相關資訊，以方便您日後再瀏覽該網站。
          這項資訊可以簡化一些程序，例如傳送相關的內容、讓網站瀏覽更方便等等。
          當您再回到該網站時，就可以擷取您先前提供的資訊，因此，您就可以輕鬆地使用您自訂的網站功能。
          <br><br>
          網路信標（Web Beacon）又稱為透明影像圖檔（Clear Gif）技術或動作標記（Action-Tag)，可以用來協助傳送我們網站上的 Cookie。
          這項技術是我們來編譯有關日商麒麟技術網站使用情況彙整統計資料的工具，例如有多少訪客點選了日商麒麟技術網頁、社群或電子快訊上的重要項目（例如連結或圖片）。
          我們或許會與事業夥伴分享彙整後的網站統計資料。
          <br><br>
          您可以選擇接受或拒絕 Cookie。
          大多數的網頁瀏覽器都會自動接受 Cookie，但是如果您想要的話，通常都可以修改瀏覽器的設定來拒絕 Cookie。
          如果您選擇拒絕 Cookie，可能會無法充分運用該網站或其他網站的互動式功能。
        </p>
      </div>

      <div class="term">
        <p class="term_title txt-bold">
          <span>
            使用網路信標
          </span>
        </p>
        <p>
          日商麒麟技術的某些網頁可能包含名為網路信標的電子影像（有時也稱為單點圖），它可讓日商麒麟技術計數造訪網頁的使用者。
          <br>
          日商麒麟技術禁止網路信標被用來收集或存取您的個人資訊。
          標記（Action-Tag）可以用來協助傳送我們網站上的 Cookie。
          這項技術是我們用來編譯 Tag，可以用來協助傳送我們網站上的 Cookie。
          這項技術是我們用來編譯有關日商麒麟技術網站使用情況彙整統計資料的工具，
          例如有多少訪客點選了日商麒麟技術網頁或電子快訊上的重要項目（例如連結或圖片）。
          我們或許會與事業夥伴分享彙整後的網站統計資料。
          <br><br>
          您可以選擇接受或拒絕 Cookie。
          大多數的網頁瀏覽器都會自動接受 Cookie，但是如果您想要的話，通常都可以修改瀏覽器的設定來拒絕 Cookie。
          如果您選擇拒絕 Cookie，可能會無法充分運用該網站或其他網站的互動式功能。
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import TopBanner from '@/components/public/TopBanner.vue';
import titleMixin from '@/mixins/titleMixin';

export default {
  name: 'Privacy',
  mixins: [titleMixin],
  components: {
    TopBanner,
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style
  scoped
  src="@/assets/scss/privacy.scss"
  lang="scss">
</style>
